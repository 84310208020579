// src/features/auth/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginUser } from './authAsyncThunk';
import { RootState } from '../../store';
import { IUserDetails } from '../../factory/interfaces';

export interface IAuthState {
  userDetails: IUserDetails | null,
  authenticated: boolean,
  isLogggedIn: boolean,
  authMessage: string
}

const initialState: IAuthState = {
  userDetails: null,
  authenticated: false,
  isLogggedIn: false,
  authMessage: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthorised: (state, action: PayloadAction<IAuthState>)=>{
        state.userDetails = action.payload.userDetails
        state.authenticated = action.payload.authenticated
        state.isLogggedIn = action.payload.isLogggedIn
        state.authMessage = action.payload.authMessage
    }
    // Add other authentication-related reducers as needed
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      // Handle login success if needed
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      // Handle login failure if needed
    });
  },
});

export const {setAuthorised} = authSlice.actions;
export default authSlice.reducer;
export const selectUserDetails = (state: RootState) => state.auth.userDetails;
export const selectAuthenticated = (state: RootState) => state.auth.authenticated;
export const selectLoggedIn = (state: RootState) => state.auth.isLogggedIn;
export const selectAuthMessage = (state: RootState) => state.auth.authMessage


