import React, { useEffect, useState } from 'react'
import { notify, toastStyle } from './UsersPage'
import axios from 'axios'
import { Container, Row, ToastContainer } from 'react-bootstrap';
import LabelsTable from '../components/LabelsTable';
import { DATA_URL } from '../constants';

const LabelsPage = () => {

    const [loadingLabels, setLoadingLabels] = useState(false);
    const [labelsLoaded, setLabelsLoaded] = useState(false);
    const [labelsData, setLabelsData] = useState(null);

    //Get Client Facts
    const getLabels = async()=>{
        try {

            setLoadingLabels(true)
            const labels = await axios.get(`${DATA_URL}/commons/tags`)
                       setLoadingLabels(false)

            if(labels.data.success){
                setLabelsLoaded(true)
                setLabelsData(labels.data.tags)
              
            }else{
                setLabelsLoaded(false)

             
                notify(labels.data.message, toastStyle,)

            }

        } catch (error: any) {  
             notify(error.message, toastStyle,)
           
        }
    }

    //Get admin users

    useEffect(()=>{
        getLabels()

    }, [])
  return (
       <Container fluid id='users-container'>
        <Row className=' justify-content-center py-0 g-0'>
    <ToastContainer position="top-center" className='text-capitalize'/>

                {(labelsLoaded && labelsData) &&

                <LabelsTable labelsData = {labelsData}></LabelsTable>
           

                }
               
          
        </Row>
      

    </Container>
  )
}

export default LabelsPage