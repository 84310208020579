import axios from "axios"
import { Dispatch } from "redux"
import { API_URL } from "../constants"
import { ANALYTICS_FAIL, ANALYTICS_REQUEST, ANALYTICS_SUCCESS } from "../constants/analyticsConstants"


export const getAnalytics = (plant_id: string | undefined, startDate: string | null, endDate: string | null)=> async(dispatch: Dispatch)=>{
    try {

        dispatch({type: ANALYTICS_REQUEST})

        const {data} = await axios.post(`${API_URL}/data/analytics`, {plant_id, startDate, endDate}, {withCredentials: true})

        dispatch({type: ANALYTICS_SUCCESS, payload: data})
        
    } catch (error:any) {

        dispatch({type: ANALYTICS_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message})
    }
}