import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ToastContainer, toast } from 'react-toastify';
import ClientUsersTable from '../components/ClientUsersTable';
import withAuth from '../components/authHOC';

 export  const notify = (message: string, style: any) => toast.error(message, {
        style
        });;
    //Toaster
export    const toastStyle: any =  {
        background: '#ffffff',
        color: '#0a0a0a',
        fontSize: "13px",
        padding: '2px',
        position: 'bottom-center',
        fontStyle: 'bold'
      }

export const notify_success = (message: string, style: any) => toast.success(message, {
        style
        });;

export interface loginCredentials{
        email: string,
        password: string
    }


const UsersPage = () => {

   

    //Get admin user

  return (
    <Container fluid id='users-container'>
        <Row className=' justify-content-center py-0 g-0'>
    <ToastContainer position="top-center" className='text-capitalize'/>
                <ClientUsersTable></ClientUsersTable>
           
          
        </Row>
      

    </Container>
  )
}

export default withAuth (UsersPage, ['admin'])
