import { Reducer } from "redux";
import { FACTS_LIST_REQUEST, FACTS_LIST_SUCCESS, FACTS_LIST_FAIL } from "../constants/factsConstants";


interface factsState {
    facts: any[];
    error?: string
}

const initialState : factsState = {
    facts: []
}


const factsReducer: Reducer<factsState> = (state = initialState, action)=>{

    switch(action.type){
        case FACTS_LIST_REQUEST:
            return {facts: []}
        case FACTS_LIST_SUCCESS:
            return {facts: action.payload}
        
        case FACTS_LIST_FAIL:
            return{
                error: action.payload,
                facts: [],
            }
        default:
        return state
    }

}

export default factsReducer