import React from 'react'
import {Container} from 'react-bootstrap';
import withAuth from '../components/authHOC';
import DataTable from '../components/DataTable';

const FactsPage = () => {
    
  return (
       <Container fluid id='users-container'>
       
        <DataTable></DataTable>
    </Container>
  )
}
export default withAuth (FactsPage, ['admin', 'user'])