import { Reducer } from "redux";
import { STAFF_LIST_FAIL, STAFF_LIST_REQUEST, STAFF_LIST_SUCCESS } from "../constants/staffConstants";


interface staffState {
    staff: any[];
    error?: string
}

const initialState : staffState = {
    staff: []
}


const staffReducer: Reducer<staffState> = (state = initialState, action)=>{

    switch(action.type){
        case STAFF_LIST_REQUEST:
            return {staff: []}
        case STAFF_LIST_SUCCESS:
            return {staff: action.payload}
        
        case STAFF_LIST_FAIL:
            return{
                error: action.payload,
                staff: [],
            }
        default:
        return state
    }

}

export default staffReducer