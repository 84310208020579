import React, { useEffect, useState } from 'react'
import { notify, toastStyle } from './UsersPage'
import axios from 'axios'
import { Col, Container, Row, ToastContainer } from 'react-bootstrap';
import { Button,Card,Form, Input,Modal,Skeleton, Switch} from 'antd';
import { API_SOCKET, AUTH_URL} from '../constants';
import { BoxPlotTwoTone, CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { notify_success } from './LoginPage';
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../features/auth/authSlice';
import { io } from 'socket.io-client';
const { Meta } = Card;

const socket = io(API_SOCKET);

interface IPlant {
    _id: string| undefined,
    name: string|undefined,
    device_id: string|undefined,
    location: string|undefined,
    tank_a_backwash: boolean | undefined,
    tank_b_backwash: boolean | undefined,
    backwash_time: string | number |undefined,
    backwash_period: Number |undefined,
    valve_controller: boolean |undefined,
    remote_switch: Number|undefined,
    remote_reboot: Number|undefined,
}

interface INewPlant {
    name: string,
    device_id: string,
    location: string,
}

const PlantsPage = () => {
    const [domainsLoaded, setDomainsLoaded] = useState(false);
    const [plantsData, setPlantsData] = useState<IPlant[]>();
    const [plantData, setPlantData] = useState<IPlant>({
        _id: '',
        name: undefined,
        device_id: undefined,
        location: undefined,
        tank_a_backwash: undefined,
        tank_b_backwash: undefined,
        backwash_time: undefined,
        backwash_period: undefined,
        valve_controller: undefined,
        remote_reboot: undefined,
        remote_switch: undefined
    })
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [reloadComponent, setReloadComponent] = useState(false);
    const [showNewPlantForm, setShowNewPlantForm] = useState(false) 
    const [newPlantName, setNewPlantName] = useState<string | undefined>()
    const [newPlantLocation, setNewPlantLocation] = useState<string | undefined>()
    const [newPlantIMEI, setNewPlantIMEI] = useState<string | undefined>()
    const [componentSize, setComponentSize] = useState<SizeType | 'default'>('default')
    const [editMode, setEditMode] = useState<boolean>(false)



     const user_details = useSelector(selectUserDetails)
     const user_plants = user_details?.plant


    const isAdmin = user_details && user_details?.role === 'admin'



    const showModal = () => {
    setOpen(true);
     };

    const showPlantForm = ()=>{
        setShowNewPlantForm(true)
    }

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
        setOpen(false);
        setConfirmLoading(false);
        }, 2000);
    };

    const clearPlantForm =()=>{
        setNewPlantIMEI(undefined)
        setNewPlantLocation(undefined)
        setNewPlantName(undefined)
    }

     const handleAddPlant = async () => {
        setConfirmLoading(true);
        const response = await axios.post(`${AUTH_URL}/plants`, {name: newPlantName, device_id: newPlantIMEI, location: newPlantLocation}, {withCredentials: true})
        setConfirmLoading(false)
        clearPlantForm()
        if(response.data.success){
            notify_success(`${response.data.message}`, toastStyle)
            setShowNewPlantForm(false)
            setReloadComponent(true)

        }else{
            notify(`${response.data.message}`, toastStyle)
        }
     }

    const handleCancel = () => {
        setOpen(false)
        setEditMode(false)
    };

    const handleCancelPlantForm = () => {
        setShowNewPlantForm(false)
        setEditMode(false)
    };
     

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };
    //Get Plants
    const getPlants = async()=>{
        try {
            const plants = await axios.get(`${AUTH_URL}/plants`, {withCredentials: true})
            if(plants.data.success){
                setDomainsLoaded(true)
                setPlantsData(plants.data.plants)
              
            }else{
                setDomainsLoaded(false)

             
                notify(plants.data.message, toastStyle,)

            }

        } catch (error: any) {  
             notify(error.message, toastStyle,)
           
        }
    }

    const getPlant = async (plant_id: string | undefined)=>{
        const plant = await axios.get(`${AUTH_URL}/plants/${plant_id}`)
        if(plant.data.success){
            setPlantData(plant.data.data.data)
            showModal()
            }

    }

    const saveConfigs = async()=>{
        const response = await axios.patch(`${AUTH_URL}/plants/${plantData?._id}`, plantData, {withCredentials: true})
        if(response.data.success){
            notify_success('Configurations updated successfully', toastStyle)
        }else{
            notify(`${response.data.message}`, toastStyle)
        }
    }
    useEffect(()=>{
        if(reloadComponent){
            getPlants()
            setReloadComponent(false)
        }
    }, [reloadComponent])

    useEffect(()=>{
        getPlants()

    }, [])

    useEffect(() => {
        socket.on('plantConfigurationsChanged', (newPlant) => {
            if(user_plants?.includes(newPlant._id)){

            getPlant(newPlant._id)
            }
        });

        return () => {
            socket.off('plantConfigurationsChanged');
        };
    }, []);

  return (
       <Container fluid id='users-container'>
       <Row>
       <Col>
        <h4>Plant Management</h4>
       </Col>
       <Col className='d-flex justify-content-end'>
       {
        isAdmin 
         &&
       <Button onClick={showPlantForm} className='text-uppercase small text-end rounded-0'>Add Plant</Button>
       }
       </Col>
       </Row>
        <Row className='py-0 g-0'>
            <ToastContainer position="top-center" className='text-capitalize'/>
            {
                (domainsLoaded && plantsData) && 
                <>
                    
                    {plantsData.map((plant:IPlant)=>{
                    return <Card
                                style={{ width: 300, marginTop: 16 }}
                                actions={[
                                <EditOutlined key="edit" />,
                                <SettingOutlined key="setting" onClick={()=> getPlant(plant._id)}/>,
                                ]}
                                className='mx-1 rounded-0'
                                >
                                <Skeleton loading={!domainsLoaded} avatar active>
                                <Meta
                                    avatar={<BoxPlotTwoTone/>}
                                    title={plant.name}
                                    description={plant.device_id}
                                />
                                </Skeleton>
                            </Card>
                    })}
                </>
            }
        </Row>
        <Row>
        {plantData && 
         <Modal
                title={`${plantData.name} Configurations`}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                centered={true}
                width={600}
                footer={[
                    
                    editMode &&
                    [<Button className='small rounded-0' key="delete" type="primary" >
                    <span><DeleteOutlined /></span>
                        Delete
                    </Button>,
                    <Button onClick={saveConfigs} className='small rounded-0' key="save-changes" type="primary" >
                    <span><SaveOutlined /></span>
                        Save Changes
                    </Button>,
                    <Button className='small rounded-0' key="cancel" onClick={()=>setEditMode(false)}>
                     <CloseOutlined />   Cancel editing
                    </Button>
                    
                    ],
                    !editMode && 
                    [
                        <Button className='small rounded-0 align-items-center' key="edit" onClick={()=> setEditMode(true)}>
                        <span><EditOutlined /></span> Edit Configurations
                        </Button>,
                        <Button className='small rounded-0' key="cancel" onClick={handleCancel}>
                        <CloseOutlined />   Cancel
                        </Button>,
                    ]
        
                ]}
            >
            <Row>
            <Card>
                <Form
                    layout="vertical"
                    initialValues={{ size: componentSize }}
                    onValuesChange={onFormLayoutChange}
                    size={componentSize as SizeType}
                    style={{ maxWidth: 600 }}
                    >
                    <Form.Item label="Plant Name">
                        <Input value={plantData.name} disabled={!editMode} 
                            onChange={(e) =>
                                    setPlantData((prevPlantData: IPlant) => ({
                                        ...prevPlantData,
                                        name: e.target.value,
                                    }))
                                }
                        />
                    </Form.Item>
                    <Form.Item label="IMEI">
                        <Input value={plantData.device_id} disabled={!editMode}
                        onChange={(e) =>
                                setPlantData((prevPlantData: IPlant) => ({
                                    ...prevPlantData,
                                    device_id: e.target.value,
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item label="Location">
                        <Input value={plantData.location} disabled={!editMode} 
                        onChange={(e) =>
                                setPlantData((prevPlantData: IPlant) => ({
                                    ...prevPlantData,
                                    location: e.target.value,
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item label="Back wash time">
                        <Input value={plantData.backwash_time} disabled={!editMode} 
                        onChange={(e) =>
                                setPlantData((prevPlantData: IPlant) => ({
                                    ...prevPlantData,
                                    backwash_time: e.target.value,
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item label="Tank A Back Wash" valuePropName="checked">
                        <Switch
                            onChange={(checked) =>
                                setPlantData((prevPlantData) => ({
                                    ...prevPlantData,
                                    tank_a_backwash: checked,
                                }))
                            }
                            checked={plantData?.tank_a_backwash}
                            disabled={!editMode}
                            checkedChildren="ON" unCheckedChildren="OFF" 
                        />
                    </Form.Item>


                    <Form.Item label="Tank B Back Wash" valuePropName="checked">
                         <Switch
                            onChange={(checked) =>
                                setPlantData((prevPlantData) => ({
                                    ...prevPlantData,
                                    tank_b_backwash: checked,
                                }))
                            }
                            checked={plantData?.tank_b_backwash}
                            disabled={!editMode}
                            checkedChildren="ON" unCheckedChildren="OFF" 
                        />
                    </Form.Item>
                </Form>
            </Card>
            </Row>
            </Modal>
        
        }
           
        </Row>
         <Row>
        {showNewPlantForm && 
         <Modal
                title={`Add New Plant`}
                open={showNewPlantForm}
                onOk={handleAddPlant}
                confirmLoading={confirmLoading}
                onCancel={handleCancelPlantForm}
                centered={true}
                className='rounded-0'
            >
            <Row>
            <Card className='rounded-0'>
                <Form
                layout="vertical"
                    >
                    <Form.Item label="Plant Name">
                        <Input value={newPlantName} onChange={(e)=> setNewPlantName(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="IMEI">
                        <Input value={newPlantIMEI} onChange={(e)=>setNewPlantIMEI(e.target.value)}  />
                    </Form.Item>
                    <Form.Item label="Location">
                        <Input value={newPlantLocation} onChange={(e)=>setNewPlantLocation(e.target.value)} />
                    </Form.Item>
                </Form>
            </Card>
                

            
            </Row>
            </Modal>
        
        }
           
        </Row>
      

    </Container>
  )

}

export default PlantsPage