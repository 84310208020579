import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Form, Image, Spinner,} from 'react-bootstrap'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import {BiLogIn} from 'react-icons/bi'
import axios from 'axios';
import { Flip, ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { State } from '../state/reducers';
import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd/es/select';
import { debounce } from 'lodash';
import { notify_error } from '../components/Toaster';
import { loginUser } from '../features/auth/authAsyncThunk';
import { AUTH_URL } from '../constants';




export interface loginCredentials{
        email: string,
        password: string
    }
export interface IAccRequest{
    title: string,
    surname: string,
    other_names: string,
    sex: string,
    email_address: string,
    phone_number: string,
    field: string,
    country: string,
    region: string,
    account: string,

    
}
export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

    //Toaster
    export const toastStyle: any =  {
    background: '#ffffff',
    color: '#0a0a0a',
    fontSize: "13px",
    padding: '2px',
    position: 'bottom-center',
    fontStyle: 'bold'
    }

    export const toatStyles: any = {
    position: "bottom-right",
    autoClose: 5000,
    newestOnTop: false,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "dark",
    transition: "Bounce",
    }

    export const notify = (message: string, style: any) => toast.error(message, {
    style
    });;

    export const notify_success = (message: string, style: any) => toast.success(message, {
    style
    });

     export const notify_info = (message: string, style: any) => toast.info(message, 
     {
            position: "bottom-right",
            autoClose: 5000,
            rtl: false,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            theme: "dark",
            transition: Flip

            }
     );

const LoginPage = () => {

    const [email, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("")
    const [showLoginForm, setShowLoginForm] = useState(true);
    const [showResetForm, setShowResetForm] = useState(true);
    const [showTokenForm, setShowTokenForm] = useState(false);
    const [showAccountRequestForm, setShowAccountRequestForm] = useState(false);

    const [signingIn, setSigningIn] = useState(false);
    const [resetingPassword, setResetingPassword] = useState(false);
    const [requestingToken, setRequestingToken] = useState(false);

    const userLogin = useSelector((state: State)=> state.userLogin)

    const [signupTitle, setSignupTitle] = useState("")
    const [signupEmail, setSignupEmail] = useState("")
    const [signupSurname, setSignupSurname] = useState("")
    const [signupOthernames, setSignupOthernames] = useState("")
    const [signupGender, setSignupGender] = useState("")
    const [signupPhone, setSignupPhone] = useState("")
    const [signupField, setSignupField] = useState("")
    const [signupCountry, setSignupCountry] = useState("")
    const [signupRegion, setSignupRegion] = useState("")
    const [signupAccount, setSignupAccount] = useState("")

    const [value, setValue] = useState<IAccountType[]>([]);


    const [signingUp, setSigningUp] = useState(false);
    const [accRequestData, setAccRequestData] = useState<IAccRequest>({
        title: signupTitle,
        surname: signupSurname,
        other_names: signupOthernames,
        sex: signupGender,
        email_address: signupEmail,
        phone_number: signupPhone,
        field: signupField,
        country: signupCountry,
        region: signupRegion,
        account: signupAccount,

    })

  
    const dispatch = useDispatch()
 


    const signIn = async()=>{
         dispatch(loginUser({ email, password}) as any);
        try {
            const login_response = await axios.post(`${AUTH_URL}/users/login`, {email, password})

            setSigningIn(false)
                

            if(login_response.data.success){
               notify_success(login_response.data.message, toastStyle,)
                Cookies.set('jwt', login_response.data.token, { expires: 7 });
              
            }else{
             
                notify(login_response.data.message, toastStyle,)

            }

        } catch (error) {  
           
        }
    }

    const handleShowAccountRequestForm = ()=>{
        setShowLoginForm(false)
        setShowResetForm(false);
        setShowTokenForm(false);
        setShowAccountRequestForm(true)
    }
     const requestResetToken = async()=>{
        try {
            let token_response: any = await axios.post(`${AUTH_URL}/users/forgot-password`, {email})
            setRequestingToken(false)

            if(token_response.data.success){
                notify_success(token_response.data.message, toastStyle,)
                setShowResetForm(true)
                setShowLoginForm(false)
                setShowTokenForm(false)
            }else{
                notify(token_response.data.message, toastStyle,)
            }

        } catch (error: any) {   
            notify(error.message, toastStyle,)
        }
    };



interface IAccountType {
  label: string;
  value: string;
}
function DebounceSelect<
  ValueType extends { label: React.ReactNode; value: string | number } = any,
>({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

async function fetchAccountsList(acc: string): Promise<IAccountType[]> {

  return fetch(`${AUTH_URL}/accounts/types`)
    .then((response) => response.json())
    .then((body) =>
      body.data.data.map(
        (acc: { name: string, _id: string}) => ({
          name: `${acc.name}`,
          value: acc.name,
        }),
      ),
    );
}

     const resetPassword = async()=>{
        try {
            const reset_response = await axios.post(`${AUTH_URL}/users/reset-password`, {email, otp, password: newPassword, passwordConfirm: newPasswordConfirm})
            setResetingPassword(false)
            if(reset_response.data.success){
                setShowResetForm(false)
                setShowLoginForm(true)
                setShowTokenForm(false)
                notify_success(reset_response.data.message, toastStyle)
            }else{
                notify(reset_response.data.message, toastStyle)
            }
            
        } catch (error:any) {   
            notify(error.message, toastStyle,)
        }
    }

    const sendAccountRequest = async()=>{
        const accRequestData = {
            title: signupTitle,
            surname: signupSurname,
            other_names: signupOthernames,
            sex: signupGender,
            email_address: signupEmail,
            phone_number: signupPhone,
            field: signupField,
            country: signupCountry,
            region: signupRegion,
            account: signupAccount
        }

        const res = await axios.post(`${AUTH_URL}/accounts/requests`, accRequestData)
        
        console.log(res)
        if(res.data.success) notify_success(res.data.message, toastStyle)
        if(!res.data.success) notify_error(res.data.message, toastStyle)
    }

  return (
    <Container fluid id='login-container'>
        <Row className='min-vh-100 justify-content-center py-4 g-0'>
            <Col className='my-auto mx-center col-lg-3 col-md-4 col-sm-6'>
                <Card className="bg-white rounded">
                    <CardHeader className='align-centre font600 text-uppercase text-md mb-0 p-3'>
                        {/* <Image src='/logo.png' style={{width: '50px'}}/> */}
                        <h2 className='brand-name my-3' >RPMS</h2>
                    </CardHeader>
                    <Card.Body>
                        {showLoginForm ? (<Form id='login-form'>
                            <p className='text-start'>Sign in</p>
                            <Form.Group className="mb-2 my-2  text-start" controlId="email">
                                <Form.Label className='font500 display-8 text-muted mb-1'>Email address</Form.Label>
                                <Form.Control value={email} onChange={(e)=> setUsername(e.target.value)} type="email" className='form-control-sm text-sm font500 w-100 rounded-0 null'/>
                            </Form.Group>
                            <Form.Group className="mb-2 text-start" controlId="password">
                                <Form.Label className='font500 text-muted mb-1'>Password</Form.Label>
                                <Form.Control value={password} onChange={(e)=>setPassword(e.target.value)} type="password" className='form-control-sm text-sm font500 w-100 rounded-0 null' />
                            </Form.Group>
                            <Button disabled={signingIn} className='text-uppercase text-white text-sm my-3 w-100 fw-normalbtn-primary btn-sm rounded-0' onClick={()=>{signIn();setSigningIn(true)}}> 
                            {signingIn ? 
                            (<><Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                <span>Signing in...</span></>): (<><BiLogIn></BiLogIn> Sign In</>)} 
                            </Button>
                            <Button  id='forgot-password-link' className='no-bg-button' onClick={()=>{setShowTokenForm(true); setShowLoginForm(false); setShowResetForm(false)}}>
                                <span>Forgot password?</span>
                            </Button>
                        </Form>) : 
                        
                        showTokenForm ? 

                        (<Form id='reset-form'>
                            <p className='text-start'>Enter your email address to reset your password</p>
                            <Form.Group className="mb-2 my-2  text-start" controlId="restEmail">
                                <Form.Label className='font500 display-8 text-muted mb-1'>Email address</Form.Label>
                                <Form.Control value={email} onChange={(e)=> setUsername(e.target.value)} type="email" className='form-control-sm text-sm font500 w-100 rounded-0 null'/>
                            </Form.Group>
                            <Button disabled={requestingToken} className='text-uppercase text-white text-sm my-3 w-100 fw-normalbtn-primary btn-sm rounded-0' onClick={()=> {requestResetToken(); setRequestingToken(true)}}>
                                {requestingToken ? 
                            (<><Spinner 
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                <span>Sending token...</span></>): (<><BiLogIn></BiLogIn> Send password token</>)} 
                                
                                 
                                 </Button>
                            <Button id='forgot-password-link' className='no-bg-button' onClick={()=>{setShowLoginForm(true); setShowTokenForm(false); setShowResetForm(false)}}>
                                <span>Go back to login</span>
                            </Button>
                        </Form>
                        ) :
                        showResetForm ? 

                         (<Form id='reset-form'>
                            <p className='text-start'>Set a new password</p>
                            <Form.Group className="mb-2 my-2  text-start" controlId="email">
                                <Form.Label className='font500 display-8 text-muted mb-1'>Email</Form.Label>
                                <Form.Control value={email} readOnly type="email" className='form-control-sm text-sm font500 w-100 rounded-0 null'/>
                            </Form.Group>
                            <Form.Group className="mb-2 my-2  text-start" controlId="restToken">
                                <Form.Label className='font500 display-8 text-muted mb-1'>Enter the OTP</Form.Label>
                                <Form.Control value={otp} onChange={(e)=> setOtp(e.target.value)} type="text" className='form-control-sm text-sm font500 w-100 rounded-0 null'/>
                            </Form.Group>
                            <Form.Group className="mb-2 text-start" controlId="newPassword">
                                <Form.Label className='font500 text-muted mb-1'>New password</Form.Label>
                                <Form.Control value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} type="password" className='form-control-sm text-sm font500 w-100 rounded-0 null' />
                            </Form.Group>
                            <Form.Group className="mb-2 text-start" controlId="newPasswordConfirm">
                                <Form.Label className='font500 text-muted mb-1'>Re-type new password</Form.Label>
                                <Form.Control value={newPasswordConfirm} onChange={(e)=>setNewPasswordConfirm(e.target.value)} type="password" className='form-control-sm text-sm font500 w-100 rounded-0 null' />
                            </Form.Group>
                            <Button disabled={resetingPassword} className='text-uppercase text-white text-sm my-3 w-100 fw-normalbtn-primary btn-sm rounded-0' onClick={()=>{resetPassword(); setResetingPassword(true)}}> 
                             {resetingPassword ? 
                            (<><Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                <span>Resetting password...</span></>): (<><BiLogIn></BiLogIn> Reset password
                                </>)} 
                            
                            </Button>
                            <Button id='forgot-password-link' className='no-bg-button' onClick={()=>{setShowLoginForm(true); setShowTokenForm(false); setShowResetForm(false)}}>
                                <span>Go back to login</span>
                            </Button>
                        </Form>) 
                        :
                        <></>
                        
                    }
                        


                    </Card.Body>
                </Card>
               
            </Col>
        </Row>
      

    </Container>
  )
}

export default LoginPage
