import { Reducer } from "redux";
import { ANALYTICS_FAIL, ANALYTICS_REQUEST, ANALYTICS_SUCCESS } from "../constants/analyticsConstants";


interface analyticsState {
  data: {
    [key: string]: {
      values: number[];
      timestamps: string[];
    };
  };
}
const initialState : analyticsState = {
    data: {
        water_quality: {
            values: [0],
            timestamps: ['']
        }
    }
}

const analyticsReducer: Reducer<analyticsState> = (state = initialState, action)=>{

    switch(action.type){
        case ANALYTICS_REQUEST:
            return {data: initialState}
        case ANALYTICS_SUCCESS:
            return {data: action.payload}
        
        case ANALYTICS_FAIL:
            return{
                data: initialState,
            }
        default:
        return state
    }

}

export default analyticsReducer