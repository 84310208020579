import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import factsReducer from './reducers/factsReducers';
import staffReducer from './reducers/staffReducers';
import analyticsReducer from './reducers/analyticsReducers';

const store = configureStore({
  reducer: {
    auth: authReducer,
    facts: factsReducer,
    staff: staffReducer,
    analytics: analyticsReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
