// src/features/auth/authAsyncThunk.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IAuthState, setAuthorised} from './authSlice';
import { notify_error } from '../../components/Toaster';
import { AUTH_URL } from '../../constants';

interface LoginPayload {
  email: string;
  password: string;
}

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (payload: LoginPayload, { dispatch }) => {

    try {
      const response = await axios.post(`${AUTH_URL}/users/login`, payload);
      const loggedin = response.data.success;
      const access_token = response.data.token
      const user = response.data.data.user
      localStorage.setItem('access_token', access_token)
      if(loggedin){
        dispatch(setAuthorised({
        userDetails: user,
        authenticated: loggedin,
        isLogggedIn: true,
        authMessage: 'You are logged in successfully'
      }))
      }else{
        dispatch(setAuthorised({
          userDetails: null,
          authenticated: false,
          isLogggedIn: false,
          authMessage: response.data.message
        }))
      }

      return loggedin;
    } catch (error: any) {
         dispatch(setAuthorised({
          userDetails: null,
          authenticated: false,
          isLogggedIn: false,
          authMessage: error.message
        }))
    }
  }
);

export const authorizeUser = createAsyncThunk(
  'auth/authorizeUser',
  async (_, { dispatch }) => {
    const access_token = localStorage.getItem('access_token')
      axios.defaults.headers.common['Authorization']= `Bearer ${access_token}`;
    try {
      const response: any = await axios.post(`${AUTH_URL}/users/authenticate`, {}, {withCredentials: true}); 
      const authenticated = response.data.success;

      if(authenticated){
        const isLogggedIn = true
        const userDetails = response.data.userDetails
        const authData: IAuthState = {
          userDetails: userDetails,
          authenticated: authenticated,
          isLogggedIn: isLogggedIn,
          authMessage: response.data.message

          }
      dispatch(setAuthorised(authData));
      return authData;
        
      }
      else{
        const isLogggedIn = false
        const userDetails = null
        const authData: IAuthState ={
          userDetails: userDetails,
          authenticated: false,
          isLogggedIn: isLogggedIn,
          authMessage: response.data.message
        }
      dispatch(setAuthorised(authData));
        return authData
      }
    
    } catch (error:any) {
      dispatch(setAuthorised(
        {userDetails: null,
        authenticated: false,
        isLogggedIn: false,
        authMessage: error.message
        }));
      
    }
  }
);

export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (_, { dispatch }) => {
    try {
      const response = await axios.get(`${AUTH_URL}/users/logout`, {withCredentials: true});
      const loggedout = response.data.success;
      if(loggedout){
         localStorage.removeItem('access_token')
         dispatch(setAuthorised({
          userDetails: null,
          authenticated: false,
          isLogggedIn: false,
          authMessage: response.data.message
      }));

      }
     
      return loggedout;
    } catch (error) {
      throw error;
    }
  }
);
