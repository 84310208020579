import { toast } from "react-toastify";

    //Toaster
   export  const toastStyle: any =  {
        background: '#ffffff',
        color: '#0a0a0a',
        fontSize: "13px",
        padding: '2px',
        position: 'bottom-center',
        fontStyle: 'bold'
      }

    export const notify_error = (message: string, style: any) => toast.error(message, {
        style
        });;
        
    export const notify_success = (message: string, style: any) => toast.success(message, {
        style
        });;
