import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Select, Table } from 'antd'
import axios from 'axios'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { AUTH_URL } from '../constants'
import { CloseOutlined, EditFilled, SaveOutlined, UserAddOutlined } from '@ant-design/icons'
import { notify, notify_success, toastStyle } from '../pages/LoginPage'
import { TfiReload } from 'react-icons/tfi'

//Interfaces
export interface IUser {
        _id: string | undefined,
        surname: string | undefined,
        other_names: string | undefined,
        phone: string | number | undefined,
        email: string | number | undefined,
        plant: string[]| undefined,
        role: string | number | undefined,
        active?: boolean
    }

export interface IPlant  {
  name: string,
  _id: string
}

const ClientUsersTable = () => {

    //variable/states
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userLoaded, setUserLoaded] = useState(false);
    const [newUserSurname, setnewUserSurname]= useState<string | undefined>()
    const [newUserOthername, setnewUserOthername]= useState<string | undefined>()
    const [newUserEmail, setnewUserEmail]= useState<string | undefined>()
    const [newUserPhone, setnewUserPhone]= useState<string | undefined>()
    const [newUserPassword, setnewUserPassword]= useState<string | undefined>()
    const [newUserPasswordConfirm, setnewUserPasswordConfirm]= useState<string | undefined>()
    const [showNewUserForm, setShowNewUserForm]= useState<boolean>(false)
    const [confirmLoading, setConfirmLoading]= useState<boolean>(false)
    const [plants, setPlants]= useState<IPlant[]>()
    const [selectedPlant, setSelectedPlant] = useState<string>();
    const [reloadComponent, setReloadComponent] = useState(false);
    const [editMode, setEditMode]= useState<boolean>(false)
    const [userData, setUserData] = useState<IUser | null>({
      _id: undefined,
      surname: undefined,
      other_names: undefined,
      phone: undefined,
      email: undefined,
      plant: undefined,
      role: undefined
    });
    const [userSurname, setUserSurname]= useState<string | undefined>()
    const [userOthername, setUserOthername]= useState<string | undefined>()
    const [userEmail, setUserEmail]= useState<string | undefined>()
    const [userPhone, setUserPhone]= useState<string | undefined>()
    const [userPlant, setUserPlant]= useState<string | undefined>()
    const [selectedUser, setSelectedUser]= useState<IUser>()
    const [usersLoaded, setUsersLoaded] = useState(false);
    const [usersData, setUsersData] = useState();
    const [loadingClientUsers, setLoadingClientUsers] = useState(false);

//Get Client users
    const getClientUsers = async()=>{
        try {

            setLoadingClientUsers(true)
            const users_request_response = await axios.get(`${AUTH_URL}/users/`)
            setLoadingClientUsers(false)
            if(users_request_response.data.success){
                setUsersLoaded(true)
                setUsersData(users_request_response.data.users)
              
            }else{
                setUsersLoaded(false)

             
                notify(users_request_response.data.message, toastStyle,)

            }

        } catch (error: any) {  
             notify(error.message, toastStyle,)
           
        }
    }

    const clearUserData = ()=> setUserData(null)

    //Components


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    clearUserData()
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    clearUserData()
  };

  const clearFields = ()=>{
    setnewUserSurname('')
    setnewUserEmail('')
    setnewUserOthername('')
    setnewUserPhone('')
    setnewUserPassword('')
    setnewUserPasswordConfirm('')
  }

  const handleRowClick = async (record: any) => {
  setSelectedUser(record._id)

        const user_data = await axios.get(`${AUTH_URL}/users/${record._id}`)
        console.log(user_data)
        if(user_data.data.success){

            setUserData(user_data.data.user)
            setUserLoaded(true)
            showModal()
        }
  }

  const columns = [
  {
    title: 'No.',
    dataIndex: 'count',
    render: (text:any, record:any, index:any) => index + 1,
  },
  {
    title: 'Surname',
    dataIndex: 'surname',
    key: 'surname',
  },
    {
    title: 'Other names',
    dataIndex: 'other_names',
    key: 'other_names',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone',
    key: 'phone',
  },
   {
    title: 'Site/Plant',
    dataIndex: 'plant',
    key: 'plant',
    render: (plantIds: any) => {
     return plantIds ? plantIds.join(', ') : '';
  },
  },
  {
    title: 'Action',
    dataIndex: '',
    key: '_id',
    render: (text: string, record: any) => <Button onClick={()=>handleRowClick(record)}>View</Button>,
  },


];

  const handleCancelUserForm = ()=>{
    setShowNewUserForm(false)
  }

  const getPlantNames = async()=> {
    
  const response = await axios.get(`${AUTH_URL}/plant-names`)
  if(response.data.success){
      setPlants(response.data.plants)
  }else{
    notify('There are no plants in the database', toastStyle)
  }
  } 

  const handleAddUser = async ()=>{
    setConfirmLoading(true)
    const response = await axios.post(`${AUTH_URL}/users/signup`, {surname: newUserSurname, other_names: newUserOthername, email: newUserEmail, phone: newUserPhone, plant: selectedPlant, password: newUserPassword, passwordConfirm: newUserPasswordConfirm }, {withCredentials: true})
    setConfirmLoading(true)
    if(response.data.success){
      notify_success(`${response.data.message}`, toastStyle)
      clearFields()
    }else{
      notify(`${response.data.message}`, toastStyle)
    }
  }

  const handleEditUser = async()=>{
      const response = await axios.patch(`${AUTH_URL}/users/${userData?._id}`, userData, {withCredentials: true})
      if(response.data.success){
        notify_success('User details updated successfully', toastStyle)
        setReloadComponent(true)
        setIsModalOpen(false)
      }else{
        console.log(userPlant)
        notify(`User cannot be update now! Contact admin`, toastStyle)
        handleCancel()
      }
  }
  


  useEffect(()=>{
    getPlantNames()
    getClientUsers()
  },[reloadComponent])

  return (
    <Container fluid>
     <Row>
       <Col>
        <h4>User Management</h4>
       </Col>
       <Col className='d-flex justify-content-end'>
       <Button onClick={()=> setShowNewUserForm(true)} className='text-uppercase small text-end rounded-0'> <UserAddOutlined />{confirmLoading ? 'Adding User' : 'Add User' }</Button>

       </Col>
      </Row>
      <Row className='my-2'>
          <Table style={{borderRadius: 0}} bordered dataSource={usersData} columns={columns}/>
      
      </Row>

        {(userData && userLoaded) && 
         <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
         title={`User Details`}
         centered
         footer={[
                    editMode 
                    ? [
                        <Button className='rounded-0' key="edit_user" onClick={handleEditUser}>
                           <span><SaveOutlined /></span>Save Changes
                         </Button>,
                         <Button className='rounded-0' key="edit_mode_off" onClick={(e)=>setEditMode(false)}>
                           <span><CloseOutlined /> </span>Cancel Editing
                         </Button>,

                     ]
                    : 
                      [

                      <Button className='rounded-0' key="edit_mode_on" onClick={(e)=>setEditMode(true)}>
                        <span><EditFilled /></span>Edit User
                      </Button>,
                      <Button  className='rounded-0' key="cancel" onClick={handleCancel}>
                      <CloseOutlined />   Cancel
                      </Button>

                      ]
                     
                    
                    
                ]}
         >
         <Card className='p-2 rounded-0'>
                <Form
                layout="vertical"
                    >
                    <Form.Item 
                    label="Surname" 
                    rules={[{required: true, message: "Surname is required!"}]}
                    name="surname"
                    initialValue={userData.surname}
                    >
                        <Input disabled={!editMode} value={userSurname} 
                        onChange={(e) =>
                                setUserData((prevUserData: any) => ({
                                    ...prevUserData,
                                    surname: e.target.value,
                                }))
                            }
                         />
                    </Form.Item>
                    <Form.Item label="Other names"
                    rules={[{required: true, message: "Other name is required!"}]}
                    name="other_names"
                    initialValue={userData.other_names}
                    >
                        <Input disabled={!editMode} value={userOthername} 
                        onChange={(e) =>
                                setUserData((prevUserData: any) => ({
                                    ...prevUserData,
                                    other_names: e.target.value,
                                }))
                            }  
                        />
                    </Form.Item>
                    <Form.Item 
                    label="Email"
                    rules={[{required: true, message: "Email is required!"}]}
                    name="email"
                    initialValue={userData.email}

                    >
                        <Input disabled={!editMode} value={userEmail} 
                          onChange={(e) =>
                                setUserData((prevUserData: any) => ({
                                    ...prevUserData,
                                    email: e.target.value,
                                }))
                            }
                         />
                    </Form.Item>
                    <Form.Item 
                      label="Phone"
                      rules={[{required: true, message: "Phone number is required!"}]}
                      name="phone"
                      initialValue={userData.phone}

                      >
                        <Input disabled={!editMode}
                          onChange={(e) =>
                                setUserData((prevUserData: any) => ({
                                    ...prevUserData,
                                    phone: e.target.value,
                                }))
                            }
                         />
                    </Form.Item>
                   <Form.Item
                      label="Select Plant/Site"
                      name="plant"
                      rules={[{ required: true, message: "User must belong to a plant/site!" }]}
                      initialValue={userData.plant ? userData.plant : undefined}
                    >
                      <Select
                        mode='multiple'
                        allowClear
                        placeholder="Select plant name"
                        value={userPlant}
                        style={{ width: '100%' }}
                        options={plants?.map((plant) => ({
                          value: plant._id,
                          label: plant.name,
                        }))}
                        disabled={!editMode}
                        onChange={(value) =>
                                      setUserData((prevUserData: any) => ({
                                          ...prevUserData,
                                          plant: value,
                                      }))
                                  }
                      />
                    </Form.Item>

                </Form>
            </Card>
      </Modal>
        }
             <Row>
        {showNewUserForm && 
         <Modal
                title={`Add New User`}
                open={showNewUserForm}
                onOk={handleAddUser}
                confirmLoading={confirmLoading}
                onCancel={handleCancelUserForm}
                centered={true}
                footer={[
                    <Button className='rounded-0' key="edit" onClick={handleAddUser}>
                       <span><UserAddOutlined /></span>Add User
                    </Button>,
                    <Button  className='rounded-0' key="cancel" onClick={handleCancelUserForm}>
                     <CloseOutlined />   Cancel
                    </Button>,
                ]}
            >
            <Row>
            <Card className='py-3 rounded-0'>
                <Form
                layout="vertical"
                    >
                    <Form.Item 
                    label="Surname" 
                    rules={[{required: true, message: "Surname is required!"}]}
                    name="surname"
                    >
                        <Input value={newUserSurname} onChange={(e)=> setnewUserSurname(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Other names"
                    rules={[{required: true, message: "Other name is required!"}]}
                    name="other_names"
                    >
                        <Input value={newUserOthername} onChange={(e)=>setnewUserOthername(e.target.value)}  />
                    </Form.Item>
                    <Form.Item 
                    label="Email"
                    rules={[{required: true, message: "Email is required!"}]}
                    name="email"
                    >
                        <Input value={newUserEmail} onChange={(e)=>setnewUserEmail(e.target.value)} />
                    </Form.Item>
                    <Form.Item 
                      label="Phone"
                      rules={[{required: true, message: "Phone number is required!"}]}
                      name="phone"
                      >
                        <Input value={newUserPhone} onChange={(e)=>setnewUserPhone(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                      label="Select Plant/Site"
                      name="plant"
                      rules={[{required: true, message: "User must belong to a plant/site!"}]}

                    >

                    <Select
                        mode='multiple'
                        allowClear
                        placeholder="Select plant name"
                        value={selectedPlant}
                        onChange={setSelectedPlant}
                        style={{ width: '100%' }}
                        options={plants?.map((plant) => ({
                          value: plant._id,
                          label: plant.name,
                        }))}
                      />
                    
                    </Form.Item>
                    
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[{ required: true, message: 'Please input your password!' }]}
                      initialValue={newUserPassword}
                    >
                      <Input.Password onChange={(e)=>setnewUserPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                      label="Confirm password"
                      name="passwordConfirm"
                      rules={[{ required: true, message: 'Please confirm password!' }]}
                      initialValue={newUserPasswordConfirm}
              
                    >
                      <Input.Password onChange={(e)=>setnewUserPasswordConfirm(e.target.value)}/>
                    </Form.Item>
                </Form>
            </Card>
                

            
            </Row>
            </Modal>
        
        }
           
        </Row>
     
    </Container>
    
  )
}

export default ClientUsersTable
