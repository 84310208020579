import axios from "axios"
import { FACTS_LIST_REQUEST, FACTS_LIST_SUCCESS, FACTS_LIST_FAIL } from "../constants/factsConstants"
import { Dispatch } from "redux"
import { API_URL } from "../constants"


export const listFacts = (domain: string | undefined, startDate: string | null, endDate: string | null)=> async(dispatch: Dispatch)=>{
    try {

        dispatch({type: FACTS_LIST_REQUEST})

        const {data} = await axios.post(`${API_URL}/data`, {plant: domain, startDate, endDate}, {withCredentials: true})

        dispatch({type: FACTS_LIST_SUCCESS, payload: data})
        
    } catch (error:any) {

        dispatch({type: FACTS_LIST_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message})
    }
}