// AuthHOC.tsx
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate} from 'react-router-dom';
import { selectAuthenticated, selectUserDetails } from '../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { authorizeUser } from '../features/auth/authAsyncThunk';
import { IUserDetails } from '../factory/interfaces';

interface AuthWrapperProps {
  // Add any additional props you want to pass to the wrapped component
}

const withAuth = <P extends AuthWrapperProps>(
  WrappedComponent: React.ComponentType<P>,
  allowedRoles: string[]
) => {
  const AuthWrapper: React.FC<P> = (props) => {
    const [isLoading, setLoading] = useState(false);
    const authenticated = useSelector(selectAuthenticated);
    // const userDetails = useSelector(selectUserDetails);
    const [userDetails, setUserDetails] = useState<IUserDetails | null>(null)
    const [userRole, setUserRole]=useState('')
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    const dispatch = useDispatch()
    useEffect(()=>{
      dispatch(authorizeUser() as any)
      .then((authData: any) => {
      setIsLoggedIn(authData.payload.isLogggedIn);
      setIsAuthenticated(authData.payload.authenticated);
      setUserDetails(authData.payload.userDetails)
      setUserRole(authData.payload.userDetails.role)

      })
  }, [])

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (isAuthenticated && userRole && allowedRoles.includes(userRole)) {
     
      return <WrappedComponent {...props} />;
    } else {
      
      return <Navigate to="/" />
    }
  };

  return AuthWrapper;
};

export default withAuth;
