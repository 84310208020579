import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Form, Input, Table, Modal, Tag } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { InfoCircleOutlined } from '@ant-design/icons';
import { notify_success, toastStyle } from './Toaster';
import { notify } from '../pages/UsersPage';
import { DATA_URL } from '../constants';

type RequiredMark = boolean | 'optional' | 'customize';

const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
  <>
    {required ? <Tag color="error">Required</Tag> : <Tag color="warning">optional</Tag>}
    {label}
  </>
);


        //Interfaces
export interface ILabel {
  name: string,
  description: string,
  createdAt: string

}

const LabelsTable = (labelsData: any) => {
 const labels = labelsData.labelsData

    //variable/states
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [labelLoaded, setLabelLoaded] = useState(false);
    const [labelData, setLabelData] = useState<ILabel | null >()
    const [showLabelForm, setShowLabelForm]= useState(false)
    const [labelName, setLabelName] = useState<string>('')
    const [labelDescription, setLabelDescription] = useState<string>('')



     const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional');

  const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
    setRequiredMarkType(requiredMarkValue);
  };



 const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
   {
    title: 'Date of Creation',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Action',
    dataIndex: '',
    key: '_id',
    render: () => <Button>Open</Button>,
  },


];


    //Components

    
    const clearFactData = ()=> setLabelData(null)

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    clearFactData()
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    clearFactData()
    setShowLabelForm(false)
  };

 const handleRowClick = async (record: any) => {
    // Assuming 'name' is the key you want to log for the clicked row
    try {

        const label_data = await axios.get(`${DATA_URL}/labels/${record._id},`)
        if(label_data.data.success){

            setLabelData(label_data.data.data.data)
            setLabelLoaded(true)
            showModal()
        }
        
    } catch (error) {
        
    }
  };

  const addLabel =async (name: string, description: string) => {

    const res = await axios.post(`${DATA_URL}/commons/tags`, {name, description}, {withCredentials: true})

     if(res.data.success){
            notify_success(res.data.message, toastStyle,)
            showModal()
        }else{
                notify(res.data.message, toastStyle,)

          
        }
    
  }

  return (
    <>

    <Row className='py-3'>
          <Col></Col>
          <Col className='d-flex justify-content-end'>
          <Button onClick={()=>setShowLabelForm(true)} className='text-sm fw-bold text-uppercase' type="primary" icon={<PlusSquareOutlined />} size={"small"}>Add New Label</Button>
          </Col>

    </Row>
    <Table bordered dataSource={labels} columns={columns} 
      onRow={(record, rowIndex) => {
        return {
          onClick: () => handleRowClick(record),
        };
      }} />

    
           {(showLabelForm) && 
         <Modal width={800} open={showLabelForm} onOk={handleOk} onCancel={handleCancel}>
        <Card>
            <Card.Header>
                <Row className='justify-center'>
                    <Col className='text-uppercase fw-bold'>
                    Add new label
                    
                    </Col>
                </Row>
                
            </Card.Header>
            <Card.Body>
                <Row>

                    <Form
      form={form}
      layout="vertical"
      initialValues={{ requiredMarkValue: requiredMark }}
      onValuesChange={onRequiredTypeChange}

    >

      <Form.Item label="Name" required tooltip="Provide the label name">
        <Input onChange={(e)=>setLabelName(e.target.value)} placeholder="input placeholder" />
      </Form.Item>
      <Form.Item label="Descriptiojn" required tooltip={{ title: 'Provide description for the label', icon: <InfoCircleOutlined /> }}>

          <TextArea showCount maxLength={100}
          onChange={(e)=>setLabelDescription(e.target.value)}
          
          />
          
      </Form.Item>
      <Form.Item>
        <Button onClick={()=>addLabel(labelName, labelDescription)} type="primary">Submit</Button>
      </Form.Item>
    </Form>
                </Row>

            </Card.Body>
        </Card>
      </Modal>
        }
    </>
  )
}

export default LabelsTable