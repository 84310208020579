import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


export interface loginCredentials{
        email: string,
        password: string
    }


const AdminUsersPage = () => {
  return (
    <Container fluid id='users-container'>
        <Row className=' justify-content-center py-0 g-0'>
        </Row>
      

    </Container>
  )
}

export default AdminUsersPage
